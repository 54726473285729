import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const blogmiti = { template: '<div>blogmiti</div>'}

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: HomeView
  },
  {
    path: '/services',
    name: 'servicios',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServicesView.vue')
  },
  { 
    path: '/blogmiti',
    beforeEnter() {location.href = 'https://blog.mitikuin.com/'},
    component: blogmiti
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
