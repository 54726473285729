<template>
<div class="row col-xs-12 col-sm-12 col-md-12 col-lg-6">
<form action="/form_page.php">
  <h2>Contacto</h2> <br><br>
  <label class="form-label" for="fname">Nombre:</label><br>
  <input class="form-control" type="text" id="fname" value="" v-model="form.name"><br>
  <label class="form-label" for="lname">Apellidos:</label><br>
  <input class="form-control" type="text" id="lname" value="" v-model="form.lastname"><br>
  <label class="form-label" for="age">Edad:</label><br>
  <input class="form-control" type="number" id="age" name="age" min="5" max="120" v-model="form.age"><br>
  <label class="form-label" for="email">Email:</label><br>
  <input class="form-control" type="email" id="email" name="email" v-model="form.email"><br>
  <input class="topaling btn btn-success btn1" type="button" value="Enviar" v-on:click="save()">
</form>
</div>
    
</template>
<style scoped>
      .btn1{
        -webkit-text-fill-color: aliceblue;
        color: #0a5161;
        background-color: #0a5161;
        border-color: #0a5161;
}

.topaling{
  
  background: linear-gradient(90deg, rgb(231, 119, 15 / 30%), rgba(173, 8, 151, 0.4) 100%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  padding: 20px;
  margin: 5px;
  font-weight: bold;
  
}
</style>
<script>
  export default {
   name:"FormMiti",

   data:function() {
    return{
      form:{
        "name":"",
        "lastname":"",
        "age":"",
        "email":"",
      }
    }    
   },
    methods:{
      save(){ 
        console.log(this.form) 
      }
    }
}
</script>