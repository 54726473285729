<template>
<SiderbarMiti class="bloque"></SiderbarMiti>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex justify-content-center">
    <div class="container" >
      <HeaderMiti></HeaderMiti> 
      <div id="app">       
      </div>

      <section class="justify-content-center" >
         <div class="row justify-content-center">
          <div class="col-xs-3 col-md-3 col-xl-1">   
           </div>
          <div class="col-xs-3 col-md-3 col-xl-2 disappear topaling">
            <h3 ><router-link class="hover" style="text-decoration: none" to="/services">Servicios</router-link></h3> 
       
           </div>
           <div class="col-xs-3 col-md-3 col-xl-1 ">       
           </div>
           <div class="col-xs-3 col-md-3 col-xl-4 disappear topaling">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
             <h3><a class="hover" href="http://blog.mitikuin.com/" style="text-decoration:none">Blog</a></h3>
           </div>
          </div>
      </section>

      <section class="d-flex justify-content-center">
        <FormMiti></FormMiti>
      </section>
        
</div>
      
   </section><!-- End Hero -->
</template>

<script>
// @ is an alias to /src
import HeaderMiti from '@/components/HeaderMiti.vue'
import FormMiti from '@/components/FormMiti.vue';
import SiderbarMiti from '@/components/SiderbarMiti.vue';


export default {
  name: 'HomeView',
  components: {
    HeaderMiti,
    FormMiti,
    SiderbarMiti,
    },
    
}

</script>
<style>
  .object-float{
    color: white;
    padding: 6px 20px 12px;
    border-radius: 6px;
    z-index: 3;
    position: fixed;
    min-width: 284px;
    min-height: initial;
    width: 280px;
    
    
    backdrop-filter: blur(23px);
    background: linear-gradient(90deg, rgb(231, 119, 15 / 30%), rgba(1, 21, 41, 0.4) 100%);
    right: 40px;
    top: 250px;
    transition: background 0.5s ease 0s;
    border-radius: 6px;
    overflow: hidden;
}    


  .form1{
    padding-top: 10%;
  }

  .fondoblur1{
    background: linear-gradient(90deg, rgb(231, 119, 15 / 30%), rgba(173, 8, 151, 0.4) 100%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  .fondoblur2 {
    background-color: linear-gradient(90deg, rgb(231, 119, 15 / 30%), rgba(173, 8, 151, 0.4) 100%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    border-radius: 6px;
    color: var(--leo-color-text-primary);
    font-family: Poppins;
    padding: 8px 20px 14px;
    text-align: center;
}

  .topaling{
  
  background: linear-gradient(90deg, rgb(231, 119, 15 / 30%), rgba(173, 8, 151, 0.4) 100%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  padding: 20px;
  margin: 5px;
  font-weight: bold;
  
}

.hover {
    color: #ffffff;
}

a:hover {
    color: #028fd2;
    text-decoration: none;
}

@media screen and (max-width:300px){
.bloque{
display:none
}
}

@media screen and (max-width:1200px){
.bloque{
display:none
}



}
</style>
