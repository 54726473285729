
<template>
    <div class="fondoblur1 object-float  disappear">
     
      <div>
        <div>
        <h3 class="col-xs-12 ">¡Bienvenidos a <b>Mitikuin</b>!</h3>
        <br>
          <p>En Mitikuin, nos apasiona impulsar el éxito de las pequeñas y medianas empresas 
            a través de soluciones tecnológicas innovadoras. Somos un equipo de expertos en 
            programación, dedicados a ofrecer servicios integrales de asesoría y soporte para 
            PYMEs que buscan optimizar sus operaciones y destacar en un mundo digitalmente 
            competitivo.</p>
      </div>
      </div>
    </div>   

</template>
<script>


  export default {
    name: 'SidebarMiti',
    props: {
    },
    data() {
      return {
        posts: null
      }
    },
    mounted() {
    // Llama a la función obtener() cuando el componente se monta
    },
    methods: {

  }
}
</script>
<style>

</style>